<template>
    <div class="shared-layout">
        <div class="shared-layout__controls">
            <MapControls
                :disabled-controls="{
                    accuracy: true,
                    locations: true,
                }"
            />
        </div>

        <div class="shared-layout__panel">
            <SharedMapSidePanel
                v-if="$route.name === 'sharedAssetDetails'"
                @close="handleSidePanelClose"
            >
                <router-view />
            </SharedMapSidePanel>
        </div>

        <LMap
            ref="map"
            class="shared-layout__map"
            :options="{
                attributionControl: false,
                preferCanvas: true,
                zoomControl: false,
            }"
            :max-bounds="[
                [-90, -270],
                [90, 270],
            ]"
            :max-bounds-viscosity="0.75"
            :max-zoom="activeLayer.maxZoom || baseTileOptions.maxZoom"
            :min-zoom="activeLayer.minZoom || baseTileOptions.minZoom"
            @locationfound="onLocationFound"
            @locationerror="onLocationError"
        >
            <TrackerLayer
                :trackers="trackersToDisplay"
                :excluded-from-clustering="trackersExcludedFromClustering"
                @alignMap="alignMap"
                @markerClicked="handleTrackerClick"
            />

            <TrackerTraceLayer :coordinates="activeSharedTrackerTrace" />

            <UserLayer />

            <LControlAttribution position="bottomright" :prefix="appVersion" />

            <LControlScale position="bottomleft" :imperial="false" />
        </LMap>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'
import { LControlAttribution, LControlScale, LMap } from 'vue2-leaflet'

import MapControls from '../components/redesigned/MapControls'
import SharedMapSidePanel from '../components/SharedMapSidePanel'
import TrackerLayer from '../components/TrackerLayer'
import TrackerTraceLayer from '../components/TrackerTraceLayer'
import UserLayer from '../components/UserLayer'

export default {
    name: 'SharingLayout',
    components: {
        LControlAttribution,
        LControlScale,
        LMap,
        MapControls,
        SharedMapSidePanel,
        TrackerLayer,
        TrackerTraceLayer,
        UserLayer,
    },
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION,
            baseTileOptions: {
                maxZoom: 20,
                minZoom: 2,
            },
        }
    },
    computed: {
        ...mapState('map', ['tileProviders']),
        ...mapState('sharing', ['activeSharedTrackerTrace', 'sharedTrackers']),
        ...mapGetters('map', ['activeLayer', 'defaultLayer']),
        trackersExcludedFromClustering() {
            return this.$route.params.id ? [+this.$route.params.id] : []
        },
        trackersToDisplay() {
            return this.sharedTrackers.filter(
                tracker => tracker.position.lat && tracker.position.lng
            )
        },
    },
    watch: {
        activeSharedTrackerTrace(trace) {
            if (trace.length > 1) {
                this.$refs.map.mapObject.panTo(trace[0], {
                    animate: true,
                    duration: 1,
                })
            }
        },
    },
    mounted() {
        this.setMapInstance(this.$refs.map.mapObject)

        if (this.activeLayer.layer) {
            this.$refs.map.mapObject.addLayer(this.activeLayer.layer)
        } else {
            this.$refs.map.mapObject.addLayer(this.defaultLayer.layer)
            this.setActiveLayerId(this.defaultLayer.id)
        }

        if (this.trackersToDisplay.length) {
            this.$refs.map.mapObject.fitBounds(
                this.trackersToDisplay.map(tracker => [
                    tracker.position.lat,
                    tracker.position.lng,
                ])
            )
        }
    },
    beforeDestroy() {
        this.$refs.map.mapObject.removeLayer(this.activeLayer.layer)
        this.disablePositionTracking()
    },
    methods: {
        ...mapMutations('map', [
            'setActiveLayerId',
            'setMapInstance',
            'setPositionTrackingEnabled',
            'setUserPosition',
        ]),
        areCoordinatesVisible(coordinates) {
            return coordinates.every(item =>
                this.$refs.map.mapObject.getBounds().contains(item)
            )
        },
        alignMap(bounds) {
            if (
                bounds?.length &&
                (this.$refs.map.mapObject.getZoom() < 12 ||
                    !this.areCoordinatesVisible(bounds))
            ) {
                this.$nextTick(
                    debounce(() => this.$refs.map.fitBounds(bounds), 100)
                )
            }
        },
        disablePositionTracking() {
            this.$refs.map.mapObject.stopLocate()
            this.setPositionTrackingEnabled(false)
        },
        handleSidePanelClose() {
            this.$router.push(`/share/${this.$route.params.token}`)
        },
        handleTrackerClick(id) {
            this.$router.push(`/share/${this.$route.params.token}/assets/${id}`)
        },
        onLocationFound({ latitude, longitude }) {
            this.setUserPosition({
                lat: latitude,
                lng: longitude,
            })
        },
        onLocationError() {
            this.disablePositionTracking()
        },
    },
}
</script>

<style lang="scss" scoped>
.shared-layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #f0f3f5;
    overflow: hidden;
    z-index: 0;

    &__controls {
        position: absolute;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        pointer-events: none;
        z-index: 110;
    }

    &__map {
        flex: 1;
        overflow: hidden;
        z-index: 0;
    }

    &__panel {
        position: absolute;
        top: 4rem;
        right: 0;
        margin: 1rem;
        width: 375px;
        height: 100%;
        max-height: calc(100% - 7rem);
        pointer-events: none;
        z-index: 101;
    }

    @include respond-to('for-mobile-down') {
        &__controls {
            display: none;
        }

        &__panel {
            top: 0;
            max-height: calc(100% - 2rem);
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__panel {
            width: calc(100vw - 2rem);
        }
    }
}
</style>
